import DateFnsUtils from '@date-io/date-fns';
import { faIdBadge, faUserCog } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Container, Grid, IconButton, InputAdornment, Paper, TextField, Tooltip, Typography } from '@material-ui/core';
import { VisibilityOffOutlined, VisibilityOutlined, InfoOutlined, EditOutlined } from '@material-ui/icons';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import clsx from 'clsx';
import deLocale from 'date-fns/locale/de';
import enLocale from 'date-fns/locale/en-US';
import { Form, Formik } from 'formik';
import cookies from 'js-cookie';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Prompt, useHistory } from 'react-router';
import Chips from '../../components/chips';
import DatePicker from '../../components/date-picker';
import SelectItems from '../../components/select-items';
import UnsavedModal from '../../components/unsaved-modal';
import { AuthContext } from '../../context/authContext';
import { LocationContext } from '../../context/locationContext';
import { GetUserWithPin, UpdateUserEvents, assignCredentials, assignRoles, createUserApi, getUserApi, syncUserApi, updateUserApi } from '../../service/usersApi';
import { API_REQUEST_ERROR_MESSAGE, CHIP_COLOR, CREDENTIALS_MODULE, DATE_FORMAT_YYYY_MM_DD, DATE_TIME_FORMAT, EVENTS_MODULE, GET, LANGUAGE_DE, LANGUAGE_EN, LOCATIONS_MODULE, MAX_CHARACTER_LIMIT, PAGE_TYPE, PATCH, PUT, ROLES_MODULE, USERS_MODULE, USER_PROVIDER } from '../../utility/constants';
import { convertToUTC, isArrayEqual } from '../../utility/helper';
import { GetInitialLocationId, GetInitialLocationObject, getLocation, hasLocationChange } from '../../utility/location';
import { userSchema } from '../../validation/schema';
import useStyles from './styles';
import ModalCreateCredentials from './user-create-credentials';
import { UserSkeleton } from './user-skeleton';
import UserPinValidationModal from '../../components/user-pin-validation-modal';
import EnhancedTitle from '../../components/enhanced-title';

const Content = (props) => {
  const classes = useStyles();
  const { t }   = useTranslation();
  const { initialValues, handleSubmit, id, disabled, setValidFrom, setValidUntil, handleSelectedRole, handleSelectedCredentials, handleSelectedLocation, showToaster, selectedRoles, selectedCredentials, selectedLocation, setPersonalNumber, setFirstName, setLastName, setSelectedCredentials, handleCancel, setIsNotValid, showLoading, path, hasSelected, setHasSelected, handlePermissions, isPinShown, setPin, handleClickShowPin, formikRef, pinRef, isEntraIdUser } = props;

  const getButtonLabel = () => {
    return id ? `${t('update')}` : `${t('create')}`;
  }

  const handleChange = (setter, formik) => {
    return(setter, formik);
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={userSchema}
      onSubmit={handleSubmit}
      innerRef={formikRef}
    >
      {
        formik => (
          <Form>
            {showLoading(formik.isSubmitting)}
            <Paper className={classes.paper} elevation={3}>
              <Grid id="userContainer" container spacing={2} className={classes.form}>
                <Grid item xs={12} md={12} lg={2}>
                  <TextField
                    inputProps={{
                      readOnly: disabled,
                      maxlength: MAX_CHARACTER_LIMIT.TEXT_FIELD
                    }}
                    id="userPersonalNumber"
                    label={`${t('personalNumber')}*`}
                    name="personalNumber"
                    disabled={path.includes('update') || path.includes('view')}
                    fullWidth
                    value={formik.values.personalNumber}
                    onChange={e => handleChange(setPersonalNumber(e.target.value), formik.handleChange(e))}
                    error={formik.touched.personalNumber && Boolean(formik.errors.personalNumber)}
                    helperText={t(formik.touched.personalNumber) && t(formik.errors.personalNumber)}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={3}>
                  <TextField
                    inputProps={{
                      readOnly: disabled,
                      maxlength: MAX_CHARACTER_LIMIT.TEXT_FIELD
                    }}
                    id="userFirstName"
                    label={`${t('firstName')}*`}
                    name="firstName"
                    fullWidth
                    multiline
                    disabled={path.includes(PAGE_TYPE.VIEW) || isEntraIdUser}
                    value={formik.values.firstName}
                    onChange={e => handleChange(setFirstName(e.target.value), formik.handleChange(e))}
                    error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                    helperText={t(formik.touched.firstName) && t(formik.errors.firstName)}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={3}>
                  <TextField
                    inputProps={{
                      readOnly: disabled,
                      maxlength: MAX_CHARACTER_LIMIT.TEXT_FIELD
                    }}
                    id="userLastName"
                    label={`${t('lastName')}*`}
                    name="lastName"
                    fullWidth
                    multiline
                    disabled={path.includes(PAGE_TYPE.VIEW) || isEntraIdUser}
                    value={formik.values.lastName}
                    onChange={e => handleChange(setLastName(e.target.value), formik.handleChange(e))}
                    error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                    helperText={t(formik.touched.lastName) && t(formik.errors.lastName)}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={2}>
                  <DatePicker
                    name="userValidFrom"
                    disabled={disabled}
                    label={t('validFrom')}
                    until={formik.values.validUntil}
                    value={formik.values.validFrom}
                    handleChange={setValidFrom}
                    touched={formik.touched.validFrom}
                    error={formik.errors.validFrom}
                    setIsNotValid={setIsNotValid}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={2}>
                  <DatePicker
                    name="userValidUntil"
                    disabled={disabled}
                    label={t('validUntil')}
                    min={formik.values.validUntil}
                    from={formik.values.validFrom}
                    value={formik.values.validUntil}
                    handleChange={setValidUntil}
                    touched={formik.touched.validUntil}
                    error={formik.errors.validUntil}
                    setIsNotValid={setIsNotValid}
                  />
                </Grid>
                <Grid container spacing={2} className={clsx(path.includes('update') || path.includes('create') ? 'hidden' : classes.location)}>
                  <Grid item xs={12} sm={6} md={6} lg={3} className={clsx(!handlePermissions(LOCATIONS_MODULE, GET) && 'hidden')}>
                    <SelectItems 
                      id="userViewLocation"
                      disabled={disabled}
                      name="Locations"
                      onChange={handleSelectedLocation}
                      selectedItems={selectedLocation}
                      showToaster={showToaster}
                      single={true}
                      required={true}
                      handlePermissions={handlePermissions}
                    />
                  </Grid>
                  {
                    formik.values.pin &&
                    <Grid item xs={12} sm={6} md={6} lg={3}>
                      <TextField
                        id="userViewPin"
                        label={t('user-page.pin')}
                        name="userViewPin"
                        fullWidth
                        disabled={path.includes(PAGE_TYPE.VIEW)}
                        value={formik.values.pin}
                        type={isPinShown ? 'text' : 'password'}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle pin visibility"
                                onClick={handleClickShowPin}
                                edge="end"
                              >
                                { isPinShown ? <VisibilityOutlined/> : <VisibilityOffOutlined/> }
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                    </Grid>
                  }
                </Grid>
                <Grid id="userCredentialsContainer" item xs={12} sm={12} md={12} lg={6} className={clsx((!handlePermissions(CREDENTIALS_MODULE, PUT) || disabled) && 'hidden')}>
                  <fieldset className={classes.containerCredentials}>
                    <legend className={classes.fieldsetLegend}>{t('credentials')}</legend>
                    <ModalCreateCredentials showToaster={showToaster} setSelectedCredentials={setSelectedCredentials} showLoading={showLoading} userValidFrom={formik.values.validFrom} userValidUntil={formik.values.validUntil} setHasSelected={setHasSelected} handlePermissions={handlePermissions}/>
                    <hr className={classes.hrDivider} />
                    <SelectItems 
                      id="userCredentials"
                      name="Credentials"
                      onChange={handleSelectedCredentials}
                      selectedCredentials={selectedCredentials}
                      showToaster={showToaster}
                      hasSelected={hasSelected} 
                      setHasSelected={setHasSelected}
                      handlePermissions={handlePermissions}
                    />
                  </fieldset>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} className={clsx(disabled && 'hidden')}>
                  {
                    handlePermissions(ROLES_MODULE, PUT) && 
                      <SelectItems 
                        id="userRoles"
                        name="Roles"
                        onChange={handleSelectedRole}
                        selectedItems={selectedRoles}
                        showToaster={showToaster}
                        handlePermissions={handlePermissions}
                      />
                  }
                  {
                    handlePermissions(LOCATIONS_MODULE, GET) &&
                      <SelectItems 
                        id="userUpdateLocation"
                        helperText={t(formik.touched.location) && t(formik.errors.location)}
                        isValid={formik.touched.location && Boolean(formik.errors.location)}
                        name="Locations"
                        onChange={handleSelectedLocation}
                        selectedItems={selectedLocation}
                        showToaster={showToaster}
                        single={true}
                        required={true}
                        handlePermissions={handlePermissions}
                      />
                  }
                  <TextField
                    id="userPin"
                    inputProps={{
                      readOnly: disabled,
                      maxlength: MAX_CHARACTER_LIMIT.PIN_FIELD
                    }}
                    inputRef={pinRef}
                    label={t('user-page.pin')}
                    type={isPinShown ? 'text' : 'password'}
                    name="userPin"
                    className={classes.pin}
                    onChange={e => handleChange(setPin(e.target.value.replace(/\D/,'')), formik.handleChange(e))}
                    value={formik.values.pin}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle pin visibility"
                            onClick={handleClickShowPin}
                            edge="end"
                          >
                            { isPinShown ? <VisibilityOutlined/> : <VisibilityOffOutlined/> }
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    error={formik.touched.pin && Boolean(formik.errors.pin)}
                    fullWidth
                  />
                  <Typography className={formik.touched.pin && Boolean(formik.errors.pin) ? classes.pinDescriptionError : classes.pinDescription}>{t('user-page.pinDescription')}</Typography>
                </Grid>
              </Grid>
              <Grid id="userButtonsContainer" container className={clsx(disabled ? 'hidden' : classes.action)}>
                <Grid item xs={12}>
                  <Button
                    id="userCancelButton"
                    className={classes.button}
                    onClick={handleCancel}
                    variant="outlined"
                    color="primary"
                    >
                    {t('cancel')}
                  </Button>
                  <Button
                    id="userSubmitButton"
                    className={classes.button}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    { getButtonLabel() }
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Form>
        )
      }
    </Formik>
  );
}

const User = (props) => {
  const { showToaster, showLoading, match, handlePermissions } = props;
  const classes = useStyles();
  const history = useHistory();
  const { t }   = useTranslation();
  
  const path   = match.path;
  const { id } = match.params;

  const { state : authState }                                  = useContext(AuthContext);
  const { defaultValidFrom, defaultValidUntil, administrator } = authState;
  
  const { state }  = useContext(LocationContext);

  const initialLocationId = GetInitialLocationId();
  const initialLocationObject = GetInitialLocationObject();

  const [credentials, setCredentials]                          = useState([]);
  const [events, setEvents ]                                   = useState([]);
  const [firstName, setFirstName]                              = useState('');
  const [isLoading, setIsLoading]                              = useState(false);
  const [isNotValid, setIsNotValid]                            = useState(false);
  const [lastName, setLastName]                                = useState('');
  const [personalNumber, setPersonalNumber]                    = useState('');
  const [prevCredentials, setPrevCredentials]                  = useState([]);
  const [prevValues, setPrevValues]                            = useState([]);
  const [prevRoles, setPrevRoles]                              = useState([]);
  const [roles, setRoles ]                                     = useState([]);
  const [showModal, setShowModal]                              = useState(false);
  const [validFrom, setValidFrom]                              = useState(defaultValidFrom);
  const [validUntil, setValidUntil]                            = useState(defaultValidUntil);
  const [withChanges, setWithChanges]                          = useState(false);
  const [toRedirect, setToRedirect]                            = useState('');
  const [checkUserChanges, setUserChanges]                     = useState(false);
  const [location, setLocation]                                = useState('');
  const [selectedLocation, setSelectedLocation]                = useState(initialLocationId);
  const [locationObject, setLocationObject]                    = useState(initialLocationObject);
  const [hasSelected, setHasSelected]                          = useState(false);
  const [pin, setPin]                                          = useState('');
  const [isPinShown, setIsPinShown]                            = useState(false);
  const [showPinValidationModal, setShowPinValidationModal]    = useState(false);
  const [isEntraIdUser, setIsEntraIdUser]                      = useState(false);

  const formikRef = useRef();
  const pinRef    = useRef(null);

  const language = cookies.get('i18next') || LANGUAGE_EN;

  const initialValues = useMemo(() => {
    return {
      personalNumber  : personalNumber,
      firstName       : firstName,
      lastName        : lastName,
      validFrom       : validFrom,
      validUntil      : validUntil,
      pin             : pin,
      location        : selectedLocation || selectedLocation === undefined ? selectedLocation : location
    }
  }, [personalNumber, firstName, lastName, validFrom, validUntil, location, selectedLocation, pin]);

  const handleCloseModal = () => {
    setShowModal(false);
  }

  const handleCancel = () => {
    history.push('/users');
  }

  const handleModalSubmit = () => {
    setWithChanges(false)
    history.push(toRedirect);
  }

  const handleModalCancel = () => {
    handleChanges();
    setShowModal(false);
  }

  const getEvents = useCallback((events) => {
    const eventData = events.map(event => {
      const date    = convertToUTC(event.dateCreated).format(DATE_TIME_FORMAT);
      
      return {
        id         : event.eventId.toString(),
        name       : event.subType,
        endContent : date
      }
    });

    setEvents(eventData);
  }, []);

  const getCredentials = (credentials) => {
    if (credentials) {
      const formattedCredentials = credentials.map(item => {
        return {
          id          : item.credentialId,
          name        : item.name,
          description : `${item.validFrom} - ${item.validUntil}`,
          endContent  : item.active === 1,
        }
      });
      setCredentials(formattedCredentials);
      setPrevCredentials(formattedCredentials);
    }
  };
  
  const getRoles = (roles) => {
    if (roles) {
      const formattedRoles = roles.map(item => {
        return {
          id   : item.roleId,
          name : item.name,
        }
      });

      setRoles(formattedRoles);
      setPrevRoles(formattedRoles);
    }
  }

  const getUser = useCallback(async () => {
    setIsLoading(true);

    try {
      const response = await getUserApi(id);
      setIsEntraIdUser(response.provider === USER_PROVIDER.LDAP ? true : false);
      getEvents(response.events);
      getCredentials(response.credentials);
      getRoles(response.roles);
      getLocation(response.data, setLocation, setLocationObject);
      
      setPersonalNumber(response.personalNumber);
      setFirstName(response.firstName);
      setLastName(response.lastName);
      setValidUntil(moment(response.validUntil).format(DATE_FORMAT_YYYY_MM_DD));
      setValidFrom(moment(response.validFrom).format(DATE_FORMAT_YYYY_MM_DD));
      setPin(response.pin);
      setPrevValues({
        personalNumber  : response.personalNumber,
        firstName       : response.firstName,
        lastName        : response.lastName,
        validFrom       : moment(response.validFrom).format(DATE_FORMAT_YYYY_MM_DD),
        validUntil      : moment(response.validUntil).format(DATE_FORMAT_YYYY_MM_DD),
        pin             : response.pin
      });
    } catch {
      showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
    } finally {
      setIsLoading(false);
    }
  }, [showToaster, t, id, getEvents]);

  const getToasterMessage = () => {
    return id ? t('updated') : t('created');
  }

  const hasChanges = (current, previous) => {
    const currentIds  = current.map(item => item.id);
    const previousIds = previous.map(item => item.id);

    return !isArrayEqual(currentIds, previousIds);
  }

  const handlePinValidationModalSubmit = () => {
    let formik = formikRef.current;
    formik.setSubmitting(true);
    
    handleSubmit(initialValues, formik);
    setShowPinValidationModal(false);
  }

  const createUserData = (values) => {
    return {
      firstName  : values.firstName,
      lastName   : values.lastName,
      enabled    : false,
      username   : values.personalNumber,
      attributes : {
        validFrom  : values.validFrom,
        validUntil : values.validUntil,
        location   : values.location,
        pin        : values.pin
      },
      groups     : [locationObject[0].name]
    }
  }

  const isPinDuplicate = async (values) => {
    const usersWithPin = await GetUserWithPin(values.pin);
    return (
      usersWithPin.length > 0 &&
      values.pin !== '' &&
      prevValues.pin !== initialValues.pin
    );
  };

  const saveUser = async (data) => {
    const response = id ? await updateUserApi(id, data) : await createUserApi(data);
    const { id: userId } = response.data.user;
    return userId;
  };

  const handleUserChanges = async (userId) => {
    if (checkUserChanges) {
      await syncUserApi(userId);
    }

    if (id && (prevValues.firstName !== firstName || 
        prevValues.lastName !== lastName)) {
      const user = `${initialValues.lastName}, ${initialValues.firstName}`;
      await UpdateUserEvents(user, userId);
    }

    const withCredentialsChange = hasChanges(prevCredentials, credentials)
    if (withCredentialsChange) {
      const currentCredentialIds = credentials.map((item) => item.id);
      await assignCredentials(currentCredentialIds, userId);
    }
    
    const withRolesChange = hasChanges(prevRoles, roles);
    if (withRolesChange) {
      const selectedRoleIds = roles.map((role) => role.id);
      await assignRoles(selectedRoleIds, userId);
    }
  };

  const handleSubmit = async (values, formik) => {
    const { setErrors, setSubmitting } = formik;

    if (isNotValid) {
      setSubmitting(false);
      return;
    }

    if (!withChanges) {
      setSubmitting(false);
      setWithChanges(false);
      history.push('/users');
      return;
    }

    const data = createUserData(values);

    try {
      const isDuplicate = await isPinDuplicate(values);
      if (isDuplicate) {
        setSubmitting(false);
        setShowPinValidationModal(true);
        return;
      }

      const userId = await saveUser(data);
      await handleUserChanges(userId);  

      setWithChanges(false);
      history.push('/users');
      showToaster(t('success'), `${values.lastName}, ${values.firstName} ${t('hasBeen')} ${getToasterMessage()}`, 'success');
    } catch (error) {
      if (error?.response?.status === 409) {
        setErrors({
          personalNumber: t('personalNumberAlreadyExists')
        });
      } else {
        showToaster(t('error'), t(API_REQUEST_ERROR_MESSAGE), 'error');
        setWithChanges(true);
      }
    } finally {
      setSubmitting(false);
    }
  }

  const handleSelectedRole = (value) => {
    setRoles(value);
  }

  const handleSelectedCredentials = (value) => {
    setCredentials(value);
  }

  const handleSelectedLocation = (value) => {
    setSelectedLocation(value[0]?.locationId);
    setLocationObject(value);
  }

  const handleClickShowPin = () => {
    setIsPinShown(!isPinShown);
  };

  const handlePinValidationModalCancel = () => {
    setShowPinValidationModal(false);
    setTimeout(() => {
      pinRef.current.focus();
    }, 100);
  }

  const handleChanges = useCallback(() => {
    const initialValidUntil = (value) => {
      return (value) ? moment(value).format(DATE_FORMAT_YYYY_MM_DD) : null;
    }
    
    const convertDate = (value) => {
      return (id) ?  value : moment(prevValues.validFrom).format(DATE_FORMAT_YYYY_MM_DD);
    }

    if (prevValues.personalNumber === initialValues.personalNumber
    && prevValues.firstName       === initialValues.firstName
    && prevValues.lastName        === initialValues.lastName
    && prevValues.validUntil      === initialValidUntil(initialValues.validUntil)
    && prevValues.validFrom       === initialValues.validFrom
    && prevValues.pin             === initialValues.pin
    && !hasChanges(prevCredentials, credentials)
    && !hasChanges(prevRoles, roles)
    && !hasLocationChange(location, locationObject[0]?.locationId, administrator.locations.length, state)
    ) {
      setWithChanges(false);
    } else {
      setWithChanges(true);
    }

    if (prevValues.personalNumber   === initialValues.personalNumber
      && prevValues.firstName       === initialValues.firstName
      && prevValues.lastName        === initialValues.lastName
      && prevValues.pin             === initialValues.pin
      && prevValues.validUntil      === initialValidUntil(initialValues.validUntil)
      && convertDate(prevValues.validFrom) === moment(initialValues.validFrom).format(DATE_FORMAT_YYYY_MM_DD)) {
        setUserChanges(false);
      } else {
        setUserChanges(true);
      }
  }, [prevValues, initialValues, credentials, id, prevCredentials, roles, prevRoles, location, locationObject, administrator.locations.length, state]);

  useEffect(() => {
    setPrevValues({
      personalNumber  : '',
      firstName       : '',
      lastName        : '',
      validFrom       : defaultValidFrom,
      validUntil      : defaultValidUntil,
      pin             : ''
    });

    if (id) {
      getUser();
    }
  }, [getUser, getEvents, id, defaultValidFrom, defaultValidUntil]);

  const getTitle = () => {
    const separator = lastName !== '' && firstName !== '' ? ', ' : '';
    const title     = lastName === '' && firstName === '' ? 'User' : lastName + separator + firstName;

    return title;
  }

  useEffect(() => {
    handleChanges();
  }, [handleChanges]);

  const titleProps = {
    title    : getTitle(),
    subtitle : getTitle(),
    isLoading
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={language === LANGUAGE_DE ? deLocale : enLocale}>
      <Container maxWidth="xl" className={classes.container}>
        <UnsavedModal
          open={showModal}
          onClose={handleCloseModal}
          handleModalSubmit={handleModalSubmit}
          handleModalCancel={handleModalCancel}
        />
        <UserPinValidationModal
          open={showPinValidationModal}
          handleSubmit={handlePinValidationModalSubmit}
          handleCancel={handlePinValidationModalCancel}
        />
        <Prompt
          when={withChanges}
          message={(location, action) => {
            if (action === 'PUSH') {
              setShowModal(true)
            }
            setWithChanges(false);
            setToRedirect(location.pathname);
            return location.pathname === '/' || location.pathname.startsWith('/persons/update') || location.pathname.startsWith('/persons/create')
          }}
        />
        <Box className={classes.details}>
          <Grid container className={classes.contentHeader}>
            <Grid item xs={10} className={classes.titleBox}>
              <EnhancedTitle {...titleProps} />
            </Grid>
            <Grid item xs={2} align="right" className={clsx(path.includes('view') ? '' : 'hidden', classes.editUserButtonContainer)}>
              <Tooltip title={t('update')} className={path.includes('view') || handlePermissions(USERS_MODULE, PATCH) ? '' : 'hidden'}>
                <Button
                  aria-label="Update"
                  id="userUpdateButton"
                  variant="contained"
                  color="primary"
                  className={classes.editUserButton}
                  startIcon={<EditOutlined />}
                  onClick={() => history.push(`../update/${id}`)}
                >
                  {t("user-page.editUser")}
                </Button>
              </Tooltip>
            </Grid>
            <Grid item className={classes.additionalInfoBox}>
              <Box className={clsx(isEntraIdUser ? classes.reminderContainer : 'hidden')}>
                <InfoOutlined className={classes.reminderIcon} />
                <Typography className={classes.reminderText}>
                  {t('user-page.entraIdUserReminder')}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          {
            isLoading ?
              <UserSkeleton
                id={id}
                disabled={path.includes('view')}
                path={path}
                handlePermissions={handlePermissions}
              />
            :
              <Content
                id={id}
                disabled={path.includes('view')}
                initialValues={initialValues}
                handleSubmit={handleSubmit}
                setValidFrom={setValidFrom}
                setValidUntil={setValidUntil}
                handleCancel={handleCancel}
                handleSelectedRole={handleSelectedRole}
                handleSelectedCredentials={handleSelectedCredentials}
                handleSelectedLocation={handleSelectedLocation}
                hasSelected={hasSelected}
                showToaster={showToaster}
                selectedRoles={roles}
                selectedCredentials={credentials}
                selectedLocation={locationObject}
                setHasSelected={setHasSelected}
                setPersonalNumber={setPersonalNumber}
                setFirstName={setFirstName}
                setLastName={setLastName}
                setSelectedCredentials={setCredentials}
                showLoading={showLoading}
                setIsNotValid={setIsNotValid}
                path={path}
                roles={administrator?.roles}
                handlePermissions={handlePermissions}
                isPinShown={isPinShown}
                setPin={setPin}
                handleClickShowPin={handleClickShowPin}
                formikRef={formikRef}
                pinRef={pinRef}
                isEntraIdUser={isEntraIdUser}
              />
          }
          <Grid container spacing={2} className={clsx(path.includes('view') ? classes.view : 'hidden')}>
            <Grid item xs={12} md={6} lg={4} className={handlePermissions(CREDENTIALS_MODULE, PUT) ? '' : 'hidden'}>
              <Chips
                id="userCredentialsPanel"
                color={CHIP_COLOR.LIGHT}
                header={t('credentials')}
                data={credentials}
                icon={<FontAwesomeIcon icon={faIdBadge}/>}
                isLoading={isLoading}
                rightHeader={'Status'}
                type={'Credentials'}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}  className={handlePermissions(ROLES_MODULE, PUT) ? '' : 'hidden'}>
              <Chips
                id="userRolesPanel"
                color={CHIP_COLOR.LIGHT}
                header={t('roles')}
                icon={<FontAwesomeIcon icon={faUserCog}/>}
                data={roles}
                isLoading={isLoading}
              />
            </Grid>
            <Grid item xs={12} lg={4}  className={handlePermissions(EVENTS_MODULE, GET) ? '' : 'hidden'}>
              <Chips
                id="userEventsPanel"
                color={CHIP_COLOR.NEUTRAL}
                header={t('events')}
                leftHeader={t('event')}
                data={events}
                isLoading={isLoading}
                rightHeader={t('dateCreated')}
                type={'Events'}
              />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </MuiPickersUtilsProvider>
  );
}

export default User;